$(document).ready(function(){
    setBgImages();
    applicationCheck();
    mobileMenu();
    initSplide();
    window.addEventListener('resize', function(event) {
        document.querySelector("body").classList.remove("menu-open");
    });
})

var mobileMenu = function() {
    $(".mobile-nav").on("click", function(e) {
        e.preventDefault(),
        $("body").toggleClass("menu-open")
    });
    $(".sub-trigger").on("click", function(e){
        e.preventDefault,
        $(this).parent().toggleClass("open");
    })
}

var setBgImages = function () {
    $('.bgimage').each(function () {
        var src = $(this).find('img').attr('src');
        if (src) {
            $(this).css('background-image', 'url("' + src + '")');
        }
    });
};

var applicationCheck = function (){
    $('.answers input').click(function(){
        var radioValue = $("input:checked").val();
        if (radioValue === "Nederland"){
            console.log('je komt in aanmerking');
            $('.agreed').html('<span>U komt in aanmerking</span><a href="#">Aanvragen</a>')
        } else {
            $('.agreed').html('<span>U komt niet in aanmerking</span>')
        }
    })
}
var initSplide = function (){
    if(document.querySelector(".splide") !== null){
        new Splide( '.splide',{
            cover  : true,
            pagination: false,
            arrows  : false,
            autoplay  : true,
            interval: 3000,
            type   : 'loop',
        } ).mount(); 
    }    
}

function contentLoader(id, data) {
    var scopeSelector = "#" + id;
    var elemsToHide = "div[id*='CCIPlaceHolder']";
    if(data <= 0){
      document.querySelector(scopeSelector).closest(elemsToHide).style.display = "none";
    }
    $(scopeSelector).html(data);
  }

  
